import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [{
  path: '',
  loadChildren: () => import('./pages/main/main.module').then(m => m.MainPageModule)
}, {
  path: 'edit-account-profile',
  loadChildren: () => import('./pages/main/children/account/children/edit-profile/edit-profile.module').then(m => m.EditProfilePageModule)
}, {
  path: 'edit-permissions',
  loadChildren: () => import('./pages/main/children/account/children/permissions/permissions.module').then(m => m.PermissionsPageModule)
}, {
  path: 'order/:uid',
  loadChildren: () => import('./pages/main/children/orders/children/order-detail/order-detail.module').then(m => m.OrderDetailPageModule)
}, {
  path: 'navigate/:order-uid/:navigate',
  loadChildren: () => import('./pages/main/children/navigator/navigator.module').then(m => m.NavigatorPageModule)
}, {
  path: 'login',
  loadChildren: () => import('./pages/auth/login/login.module').then(m => m.LoginPageModule)
}, {
  path: 'forgot-password',
  loadChildren: () => import('./pages/auth/forgot-password/forgot-password.module').then(m => m.ForgotPasswordPageModule)
}];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
